import React, { useEffect, useState } from "react";
import Navbar from "../Components/Navbar";
import "../CSS/testride.css";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { createTestRide } from "../Actions/testrideAction";
import Meta from "../Components/Meta";
import Footer from "../Components/Footer";
import axios from "axios";
import ReactGAA from "react-ga4";

const TestRideScreen = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [name, setName] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [city, setCity] = useState("");
  const [country, setCountry] = useState("");
  const [zip, setZip] = useState("");
  const [latitude, setLatitude] = useState("");
  const [longitude, setLongitude] = useState("");
  const [utmCampaign, setUtmCampaign] = useState("");
  const [gclid, setGclid] = useState("");

  const analyticEvent = (category, action) => {
    ReactGAA.event({
      category: category,
      action: action,
    });
  };

  useEffect(() => {
    // Fetching UTM campaign parameter from the URL
    const urlParams = new URLSearchParams(window.location.search);
    const campaign = urlParams.get("utm_campaign"); // Get the 'utm_campaign' parameter
    const GClid = urlParams.get("gclid");
    // Set the campaign name in the state
    if (campaign) {
      setUtmCampaign(campaign);
    }
    if (GClid) {
      setGclid(GClid);
    }
  }, []);

  useEffect(() => {
    const fetchIPAddress = async () => {
      try {
        const response = await axios.get("https://api64.ipify.org?format=json");
        if (response) {
          const clientIp = response.data.ip;
          const ipDataResponse = await axios.get(
            `https://ipapi.co/${clientIp}/json/`
          );

          setCity(ipDataResponse.data.city);
          setCountry(ipDataResponse.data.country);
          setZip(ipDataResponse.data.postal);
          setLatitude(ipDataResponse.data.latitude);
          setLongitude(ipDataResponse.data.longitude);
        }
      } catch (error) {
        console.error("Error fetching client IP:", error);
      }
    };

    fetchIPAddress();
  }, []);

  const bookTestride = async (event) => {
    try {
      event.preventDefault();

      dispatch(
        createTestRide(
          name,
          phoneNumber,
          city,
          country,
          zip,
          latitude,
          longitude,
          utmCampaign,
          gclid
        )
      );
      analyticEvent("Testride", "Testride Submitted Successfully");
      navigate("/testride-scheduled");
    } catch (error) {
      analyticEvent("Testride", "Testride Submission Failed");
      console.error("Error booking test ride:", error);
    }
  };

  const [isQuestionOne, setIsQuestionOne] = useState(false);
  const [isQuestionTwo, setIsQuestionTwo] = useState(false);
  const [isQuestionThree, setIsQuestionThree] = useState(false);
  const [isQuestionFour, setIsQuestionFour] = useState(false);
  const [isQuestionFive, setIsQuestionFive] = useState(false);
  const [isQuestionSix, setIsQuestionSix] = useState(false);
  const [isQuestionSeven, setIsQuestionSeven] = useState(false);
  const [isQuestionEight, setIsQuestionEight] = useState(false);

  const [isQuestionNine, setIsQuestionNine] = useState(false);

  const toggleQuestionOneCollapse = () => {
    setIsQuestionOne(!isQuestionOne);
  };
  const toggleQuestionTwoCollapse = () => {
    setIsQuestionTwo(!isQuestionTwo);
  };
  const toggleQuestionThreeCollapse = () => {
    setIsQuestionThree(!isQuestionThree);
  };
  const toggleQuestionFourCollapse = () => {
    setIsQuestionFour(!isQuestionFour);
  };
  const toggleQuestionFiveCollapse = () => {
    setIsQuestionFive(!isQuestionFive);
  };
  const toggleQuestionSixCollapse = () => {
    setIsQuestionSix(!isQuestionSix);
  };
  const toggleQuestionSevenCollapse = () => {
    setIsQuestionSeven(!isQuestionSeven);
  };
  const toggleQuestionEightCollapse = () => {
    setIsQuestionEight(!isQuestionEight);
  };
  const toggleQuestionNineCollapse = () => {
    setIsQuestionNine(!isQuestionNine);
  };

  return (
    <div>
      <Meta
        title={"Book Your Test Ride Now | EMotorad"}
        description={
          "EMotorad brings a range of the best electric bikes that are affordable &amp; futuristic. Crafted with the state of art components, at the best price. Book your test ride now!"
        }
        cannonicalTag={"https://emotorad.com/testride"}
      />
      <Navbar />
      <div className="testRideMainDiv d-none d-lg-block">
        <div className="testRideInnerDivDesktop ps-5 text-light">
          <h2
            className="testRideHeading mb-0"
            style={{ fontFamily: "Metropolis", fontSize: "2.3em" }}
          >
            <em>
              Ride the <span style={{ color: "#09E97E" }}>Mahi Way!</span>
            </em>
          </h2>
          <h1
            className=" testRideHeading"
            style={{ fontFamily: "Metropolis", fontSize: "2.1em" }}
          >
            <em>Book your test ride now.</em>
          </h1>
          <div className="w-75">
            <p
              className="mt-3"
              style={{
                fontFamily: "Metropolis-light",
                fontSize: "1rem",
                lineHeight: "1.3em",
              }}
            >
              <em>
                Discover the perfect electric cycle for your commute and
                adventure.
                <br></br>Ride an EMotorad electric bicycle today.
              </em>
            </p>
          </div>
          <form
            className="d-flex bg-light  p-3 mt-4 rounded-3  justify-content-between align-content-center flex-column testRideForm  "
            onSubmit={bookTestride}
          >
            <input
              type="text"
              className="form-control text-dark w-100 input-sm mb-3 "
              required
              value={name}
              autoComplete="off"
              style={{ background: "none" }}
              placeholder="Name"
              pattern="[A-Za-z\s]{2,}"
              onChange={(e) => {
                setName(e.target.value);
                e.target.setCustomValidity("");
              }}
              onInvalid={(e) =>
                e.target.setCustomValidity(
                  "Name should have at least 2 characters and contain only alphabets."
                )
              }
            />
            <input
              type="number"
              className="form-control text-dark w-100 input-sm mb-3"
              required
              value={phoneNumber}
              onChange={(e) => {
                const phoneNumber = e.target.value.slice(0, 12);
                setPhoneNumber(phoneNumber);

                if (/^[6-9]\d{9}$/.test(phoneNumber)) {
                  e.target.setCustomValidity("");
                } else {
                  e.target.setCustomValidity(
                    "Enter a valid phone number (10 digits, starting with 6, 7, 8, or 9)"
                  );
                }
              }}
              onInvalid={(e) =>
                e.target.setCustomValidity(
                  "Enter a valid phone number (10 digits, starting with 6, 7, 8, or 9)"
                )
              }
              placeholder="Phone Number"
            />
            <button
              type="submit"
              style={{ background: "#004dac" }}
              className=" btn w-100  text-light"
            >
              Book Test Ride
            </button>
          </form>
        </div>
      </div>
      <div className="d-lg-none p-3">
        <div
          className="p-3 rounded"
          style={{ border: "1px solid #004dac", background: "#E9E9E9" }}
        >
          <div className="position-relative">
            <img
              src="https://ar-euro.s3.ap-south-1.amazonaws.com/india-webiste-17-02-24/testRide+Page/Landing+Page+Banner+MSD_Mobile.jpg"
              alt=""
              className="img-fluid w-100 rounded-3"
            />
          </div>

          <form className="  mt-4 rounded-3   " onSubmit={bookTestride}>
            <div className="text-center">
              <h2
                className="mb-0"
                style={{ fontFamily: "Metropolis-bold", fontSize: "1.4em" }}
              >
                <em>Ride the Mahi Way!</em>
              </h2>
              <h1 className="" style={{ fontFamily: "", fontSize: "1.2em" }}>
                <em>Book Your Test Ride Now</em>
              </h1>
              <div className="metropolis-light text-center">
                <p
                  className=""
                  style={{
                    fontFamily: "",
                    lineHeight: "1.2em",
                    fontSize: "0.8rem",
                  }}
                >
                  Discover the perfect electric cycle for your commute and
                  adventure.
                  <br></br>Ride an EMotorad electric bicycle today.
                </p>
              </div>
            </div>

            <input
              type="text"
              className="form-control text-dark  w-100 input-sm mb-3 "
              required
              value={name}
              autoComplete="off"
              style={{}}
              placeholder="Name"
              pattern="[A-Za-z\s]{2,}"
              onChange={(e) => {
                setName(e.target.value);
                e.target.setCustomValidity("");
              }}
              onInvalid={(e) =>
                e.target.setCustomValidity(
                  "Name should have at least 2 characters and contain only alphabets."
                )
              }
            />

            <input
              type="tel"
              className="form-control text-dark w-100 input-sm mb-3"
              required
              value={phoneNumber}
              onChange={(e) => {
                const phoneNumber = e.target.value.slice(0, 12);
                setPhoneNumber(phoneNumber);

                if (/^[6-9]\d{9}$/.test(phoneNumber)) {
                  e.target.setCustomValidity("");
                } else {
                  e.target.setCustomValidity(
                    "Enter a valid phone number (10 digits, starting with 6, 7, 8, or 9)"
                  );
                }
              }}
              onInvalid={(e) =>
                e.target.setCustomValidity(
                  "Enter a valid phone number (10 digits, starting with 6, 7, 8, or 9)"
                )
              }
              placeholder="Phone Number"
            />
            <button
              type="submit"
              style={{ background: "#004dac" }}
              className=" btn w-100  mt-2 p-2 text-light"
            >
              Book Test Ride
            </button>
          </form>
        </div>
      </div>
      <div className="px-3 px-lg-5 my-5">
        <h2
          className="fw-bold mb-3 text-em heading-homepage"
          style={{
            fontFamily: "Metropolis-black",
            // letterSpacing: "1px",
          }}
        >
          FAQs
        </h2>
        <div>
          <div>
            <div
              className="d-flex justify-content-between align-items-center"
              data-bs-toggle="collapse"
              data-bs-target="#collapse1"
              aria-expanded={isQuestionOne ? "true" : "false"}
              aria-controls="collapseExample"
              onClick={toggleQuestionOneCollapse}
            >
              <h2 className="faq-heading text-em">1. What is an E Cycle?</h2>
              <span className="iconify">
                {isQuestionOne ? (
                  <i className="fa-solid fa-minus"></i>
                ) : (
                  <i className="fa-solid fa-plus"></i>
                )}
              </span>
            </div>

            <div className="collapse mt-2 question-content" id="collapse1">
              <p className="ps-4">
                An electric cycle is a bicycle with a motor and battery,
                allowing riders to utilise some fascinating features like the
                pedal assist and throttle, alongside normal pedalling. This
                feature makes it perfect for every rider, irrespective of age
                and fitness level.
              </p>
              <hr className="p-0" style={{ marginTop: "14px" }} />
            </div>
          </div>
        </div>
        {!isQuestionOne && <hr className="p-0" style={{ marginTop: "16px" }} />}
        <div>
          <div>
            <div
              className="d-flex justify-content-between align-items-center"
              data-bs-toggle="collapse"
              data-bs-target="#collapse2"
              aria-expanded={isQuestionTwo ? "true" : "false"}
              aria-controls="collapseExample"
              onClick={toggleQuestionTwoCollapse}
            >
              <h2 className="faq-heading text-em">
                2. How do electric cycles work?
              </h2>
              <span className="iconify">
                {isQuestionTwo ? (
                  <i className="fa-solid fa-minus"></i>
                ) : (
                  <i className="fa-solid fa-plus"></i>
                )}
              </span>
            </div>

            <div className="collapse mt-2 question-content" id="collapse2">
              <p className="ps-4">
                Electric cycles (e-cycles) combine a battery-powered motor with
                traditional pedal power. The motor assists the rider, reducing
                the effort exerted, especially on hills or long distances.
                Riders can adjust the level of assistance through pedal-assist
                modes.
              </p>
              <hr className="p-0" style={{ marginTop: "14px" }} />
            </div>
          </div>
        </div>
        {!isQuestionTwo && <hr className="p-0" style={{ marginTop: "16px" }} />}
        <div>
          <div>
            <div
              className="d-flex justify-content-between align-items-center"
              data-bs-toggle="collapse"
              data-bs-target="#collapse3"
              aria-expanded={isQuestionThree ? "true" : "false"}
              aria-controls="collapseExample"
              onClick={toggleQuestionThreeCollapse}
            >
              <h2 className="faq-heading text-em">
                3. What is the average speed of electric cycles?
              </h2>
              <span className="iconify">
                {isQuestionThree ? (
                  <i className="fa-solid fa-minus"></i>
                ) : (
                  <i className="fa-solid fa-plus"></i>
                )}
              </span>
            </div>

            <div className="collapse mt-2 question-content" id="collapse3">
              <p className="ps-4">
                Most e-cycles have a top speed of around 25-30 km/h, depending
                on the motor power and local regulations.
              </p>
              <hr className="p-0" style={{ marginTop: "14px" }} />
            </div>
          </div>
        </div>
        {!isQuestionThree && (
          <hr className="p-0" style={{ marginTop: "16px" }} />
        )}
        <div>
          <div>
            <div
              className="d-flex justify-content-between align-items-center"
              data-bs-toggle="collapse"
              data-bs-target="#collapse4"
              aria-expanded={isQuestionFour ? "true" : "false"}
              aria-controls="collapseExample"
              onClick={toggleQuestionFourCollapse}
            >
              <h2 className="faq-heading text-em">
                4. What is the average range of e-cycles?
              </h2>
              <span className="iconify">
                {isQuestionFour ? (
                  <i className="fa-solid fa-minus"></i>
                ) : (
                  <i className="fa-solid fa-plus"></i>
                )}
              </span>
            </div>

            <div className="collapse mt-2 question-content" id="collapse4">
              <p className="ps-4">
                On a full charge, e-cycles like EMotorad models offer an average
                50-70 km range, depending on terrain, rider weight, and pedal
                assist level.
              </p>

              <hr className="p-0" style={{ marginTop: "14px" }} />
            </div>
          </div>
        </div>
        {!isQuestionFour && (
          <hr className="p-0" style={{ marginTop: "16px" }} />
        )}
        <div>
          <div>
            <div
              className="d-flex justify-content-between align-items-center"
              data-bs-toggle="collapse"
              data-bs-target="#collapse5"
              aria-expanded={isQuestionFive ? "true" : "false"}
              aria-controls="collapseExample"
              onClick={toggleQuestionFiveCollapse}
            >
              <h2 className="faq-heading text-em">
                5. How much time does it take to charge an e-bike?
              </h2>
              <span className="iconify">
                {isQuestionFive ? (
                  <i className="fa-solid fa-minus"></i>
                ) : (
                  <i className="fa-solid fa-plus"></i>
                )}
              </span>
            </div>

            <div className="collapse mt-2 question-content" id="collapse5">
              <p className="ps-4">
                Charging times vary, but it typically takes around 4-6 hours to
                fully charge an e-bike
              </p>
              <hr className="p-0" style={{ marginTop: "14px" }} />
            </div>
          </div>
        </div>
        {!isQuestionFive && (
          <hr className="p-0" style={{ marginTop: "16px" }} />
        )}
        <div>
          <div>
            <div
              className="d-flex justify-content-between align-items-center"
              data-bs-toggle="collapse"
              data-bs-target="#collapse6"
              aria-expanded={isQuestionSix ? "true" : "false"}
              aria-controls="collapseExample"
              onClick={toggleQuestionSixCollapse}
            >
              <h2 className="faq-heading text-em">
                6. Are electric cycles waterproof?
              </h2>
              <span className="iconify">
                {isQuestionSix ? (
                  <i className="fa-solid fa-minus"></i>
                ) : (
                  <i className="fa-solid fa-plus"></i>
                )}
              </span>
            </div>

            <div className="collapse mt-2 question-content" id="collapse6">
              <p className="ps-4">
                EMotorad e-cycles are designed to handle light rain and wet
                conditions, but it's important to avoid submerging electrical
                components in water.
              </p>
              <hr className="p-0" style={{ marginTop: "14px" }} />
            </div>
          </div>
        </div>
        {!isQuestionSix && <hr className="p-0" style={{ marginTop: "16px" }} />}
        <div>
          <div>
            <div
              className="d-flex justify-content-between align-items-center"
              data-bs-toggle="collapse"
              data-bs-target="#collapse7"
              aria-expanded={isQuestionSeven ? "true" : "false"}
              aria-controls="collapseExample"
              onClick={toggleQuestionSevenCollapse}
            >
              <h2 className="faq-heading text-em">
                7. How safe are electric cycles?
              </h2>
              <span className="iconify">
                {isQuestionSeven ? (
                  <i className="fa-solid fa-minus"></i>
                ) : (
                  <i className="fa-solid fa-plus"></i>
                )}
              </span>
            </div>

            <div className="collapse mt-3 question-content" id="collapse7">
              <p className="ps-4">
                Electric cycles are generally safe with proper use. They feature
                safety mechanisms like brake cut-off systems, lights, and
                indicators. Regular maintenance ensures optimal safety.
              </p>
              <hr className="p-0" style={{ marginTop: "14px" }} />
            </div>
          </div>
        </div>
        {!isQuestionSeven && (
          <hr className="p-0" style={{ marginTop: "16px" }} />
        )}
        <div>
          <div>
            <div
              className="d-flex justify-content-between align-items-center"
              data-bs-toggle="collapse"
              data-bs-target="#collapse8"
              aria-expanded={isQuestionEight ? "true" : "false"}
              aria-controls="collapseExample"
              onClick={toggleQuestionEightCollapse}
            >
              <h2 className="faq-heading text-em">
                8. Are there any electric cycle models available for kids?
              </h2>
              <span className="iconify">
                {isQuestionEight ? (
                  <i className="fa-solid fa-minus"></i>
                ) : (
                  <i className="fa-solid fa-plus"></i>
                )}
              </span>
            </div>

            <div className="collapse mt-3 question-content" id="collapse8">
              <p className="ps-4">
                Some EMotorad e-cycle models come with lower frame heights more
                suitable for young riders. The Lil E kick scooter is meant for
                kids. Always check height and weight recommendations before
                purchasing an e-cycle.
              </p>
              <hr className="p-0" style={{ marginTop: "14px" }} />
            </div>
          </div>
        </div>
        {!isQuestionEight && (
          <hr className="p-0" style={{ marginTop: "16px" }} />
        )}
        <div>
          <div>
            <div
              className="d-flex justify-content-between align-items-center"
              data-bs-toggle="collapse"
              data-bs-target="#collapse9"
              aria-expanded={isQuestionNine ? "true" : "false"}
              aria-controls="collapseExample"
              onClick={toggleQuestionNineCollapse}
            >
              <h2 className="faq-heading text-em">
                9. Is an electric cycle legal in India?
              </h2>
              <span className="iconify">
                {isQuestionNine ? (
                  <i className="fa-solid fa-minus"></i>
                ) : (
                  <i className="fa-solid fa-plus"></i>
                )}
              </span>
            </div>

            <div className="collapse mt-3 question-content" id="collapse9">
              <p className="ps-4">
                Yes, electric cycles are legal in India. E-cycles with speeds
                below 25 km/h and power under 250W do not require a license or
                registration, making all EMotorad e-cycles perfect for everyone.
              </p>
              <hr className="p-0" style={{ marginTop: "14px" }} />
            </div>
          </div>
        </div>
        {!isQuestionNine && (
          <hr className="p-0" style={{ marginTop: "16px" }} />
        )}
      </div>
      <Footer />
    </div>
  );
};

export default TestRideScreen;

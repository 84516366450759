import React, { useEffect, useState } from "react";
import "../CSS/gudiPadwa.css";
import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore, {
  Navigation,
  Pagination,
  EffectCoverflow,
  Scrollbar,
  Autoplay,
} from "swiper";
import "swiper/swiper-bundle.min.css";
import "swiper/swiper.min.css";
import axios from "axios";
import "../CSS/doniCampaign2.css";

const DhoniCampaignScreen = () => {
  const [name, setName] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [pincode, setPincode] = useState("");
  const [showMessage, setShowMessage] = useState("");
  const [city, setCity] = useState("");
  const [gclid, setGclid] = useState("");

  useEffect(() => {
    // Fetching UTM campaign parameter from the URL
    const urlParams = new URLSearchParams(window.location.search);
    const GClid = urlParams.get("gclid");
    // Set the gclid in the state
    if (GClid) {
      setGclid(GClid);
    }
  }, []);

  useEffect(() => {
    if (pincode.length === 6) {
      axios
        .post(`api/oms/pincode`, { pincode })
        .then((response) => {
          setCity(response.data.data.district.district_name);
        })
        .catch((error) => {
          setCity("");
        });
    }
  }, [pincode]);

  const bikeFeatures = [
    {
      heading: "Easy Removable Battery",
      subText: "Charge anywhere like a phone",
      mobImage:
        "https://ar-euro.s3.ap-south-1.amazonaws.com/india-webiste-17-02-24/landing+page/gudiPadwa/Bike+Features+cards/Mobile/Mobile+1.png",
      deskImage:
        "https://ar-euro.s3.ap-south-1.amazonaws.com/india-webiste-17-02-24/landing+page/gudiPadwa/Bike+Features+cards/Web/1.png",
    },
    {
      heading: "Handlebar Integrated Light",
      subText: "India's brightest innovative lights",
      mobImage:
        "https://ar-euro.s3.ap-south-1.amazonaws.com/india-webiste-17-02-24/landing+page/gudiPadwa/Bike+Features+cards/Mobile/Mobile+2.png",
      deskImage:
        "https://ar-euro.s3.ap-south-1.amazonaws.com/india-webiste-17-02-24/landing+page/gudiPadwa/Bike+Features+cards/Web/2.png",
    },
    {
      heading: "Innovative Integrated Display",
      subText: "India’s first dynamic and sleek displays",
      mobImage:
        "https://ar-euro.s3.ap-south-1.amazonaws.com/india-webiste-17-02-24/landing+page/gudiPadwa/Bike+Features+cards/Mobile/Mobile+3.png",
      deskImage:
        "https://ar-euro.s3.ap-south-1.amazonaws.com/india-webiste-17-02-24/landing+page/gudiPadwa/Bike+Features+cards/Web/3.png",
    },
    {
      heading: "Rear Brake Lights",
      subText: "Safety, the four wheeler standard",
      mobImage:
        "https://ar-euro.s3.ap-south-1.amazonaws.com/india-webiste-17-02-24/landing+page/gudiPadwa/Bike+Features+cards/Mobile/Mobile+4.png",
      deskImage:
        "https://ar-euro.s3.ap-south-1.amazonaws.com/india-webiste-17-02-24/landing+page/gudiPadwa/Bike+Features+cards/Web/4.png",
    },
  ];

  const celebs = [
    {
      name: "Nitin Gadkari",
      designation: "Minister of Road & Transport Highways",
      image:
        "https://ar-euro.s3.ap-south-1.amazonaws.com/india-webiste-17-02-24/landing+page/gudiPadwa/Celebs/Screenshot+2023-10-05+115126.png",
    },
    {
      name: "Shantanu Mukherjee",
      designation: "Playback Singer",
      image:
        "https://ar-euro.s3.ap-south-1.amazonaws.com/india-webiste-17-02-24/landing+page/gudiPadwa/Celebs/wdwww.png",
    },
    {
      name: "Jayant Sinha",
      designation: "Member of Indian Parliament",
      image:
        "https://ar-euro.s3.ap-south-1.amazonaws.com/india-webiste-17-02-24/landing+page/gudiPadwa/Celebs/Screenshot+2023-10-05+115119.png",
    },
    {
      name: "Terence Lewis",
      designation: "Coreographer",
      image:
        "https://ar-euro.s3.ap-south-1.amazonaws.com/india-webiste-17-02-24/landing+page/gudiPadwa/Celebs/Screenshot+2023-10-05+134322.png",
    },
    {
      name: "Sunil Sheity",
      designation: "Indian Actor",
      image:
        "https://ar-euro.s3.ap-south-1.amazonaws.com/india-webiste-17-02-24/landing+page/gudiPadwa/Celebs/WhatsApp+Image+2023-10-03+at+13.11.png",
    },
    {
      name: "Sonali Bendre",
      designation: "Indian Actress",
      image:
        "https://ar-euro.s3.ap-south-1.amazonaws.com/india-webiste-17-02-24/landing+page/gudiPadwa/Celebs/fwfwff.png",
    },
    {
      name: "Aman Gupta",
      designation: "Co-founder of Boat Lifestyle",
      image:
        "https://ar-euro.s3.ap-south-1.amazonaws.com/india-webiste-17-02-24/landing+page/gudiPadwa/Celebs/011+1.png",
    },
    {
      name: "Isha Koppikar",
      designation: "Indian Actress",
      image:
        "https://ar-euro.s3.ap-south-1.amazonaws.com/india-webiste-17-02-24/landing+page/gudiPadwa/Celebs/wffwFF.png",
    },
    {
      name: "Baichung Bhutia",
      designation: "Indian Former Footballer",
      image:
        "https://ar-euro.s3.ap-south-1.amazonaws.com/india-webiste-17-02-24/landing+page/gudiPadwa/Celebs/WhatsApp+Image+2023-10-05+at+12.44.png",
    },
  ];
  SwiperCore.use([
    Navigation,
    Pagination,
    EffectCoverflow,
    Scrollbar,
    Autoplay,
  ]);
  const pincodeSubmit = async (e) => {
    e.preventDefault();

    try {
      const response = await axios.post("/api/dealer/ecycle-lead", {
        name,
        phoneNumber,
        city,
        pincode,
        gclid,
      });

      if (response) {
        setName("");
        setPhoneNumber("");
        setPincode("");
        setShowMessage(
          "Thank you! Your details have been successfully submitted. Our team will get back to you soon."
        );
      }
    } catch (error) {
      console.error("Error submitting dealer lead:", error);
    }
  };
  return (
    <>
      <div
        className="primary-background"
        style={{
          position: "fixed",
          top: 0,
          left: 0,
          width: "100vw",
          height: "100vh",
          zIndex: -1, // Keep it behind the content
        }}
      />

      <div
        className="text-light dhoni-campaign-2 inter-regular"
        // style={{ position: "relative", zIndex: 1 }}
      >
        <div className="position-relative">
          <picture className="main-banner-img">
            <source
              media="(max-width:650px)"
              srcSet="https://ar-euro.s3.ap-south-1.amazonaws.com/india-webiste-17-02-24/landing+page/gudiPadwa/Gudi+Padwa+Mobile+banner.jpg"
            />
            <source
              media="(min-width:651px)"
              srcSet="https://ar-euro.s3.ap-south-1.amazonaws.com/india-webiste-17-02-24/landing+page/gudiPadwa/Gudi+Padwa+Web+banner.png"
            />
            <img
              src="https://ar-euro.s3.ap-south-1.amazonaws.com/india-webiste-17-02-24/landing+page/gudiPadwa/Gudi+Padwa+Web+banner.png"
              alt="Banner1"
              className="d-block w-100"
            />
          </picture>
          <div
            className="position-absolute d-lg-block d-none"
            style={{ top: "50%", right: "5%", width: "30%" }}
          >
            <div className="px-3 px-lg-0">
              <div
                className="p-3 rounded-3"
                style={{ background: "white", border: "1px solid black" }}
              >
                <h5 className="mb-3 text-center text-dark">Fill the Details</h5>
                <form className="" onSubmit={pincodeSubmit}>
                  <div className="mb-3">
                    <input
                      type="text"
                      className="form-control rounded-1"
                      placeholder="Name"
                      value={name}
                      onChange={(e) => setName(e.target.value)}
                      required
                    />
                  </div>
                  <div className="d-flex" style={{ gap: "0.5em" }}>
                    <div className="mb-3 w-50">
                      <input
                        type="tel"
                        className="form-control rounded-1"
                        placeholder="Phone Number"
                        value={phoneNumber}
                        onChange={(e) => {
                          const phoneNumber = e.target.value.slice(0, 12);
                          setPhoneNumber(phoneNumber);
                          if (/^[6-9]\d{9}$/.test(phoneNumber)) {
                            e.target.setCustomValidity("");
                          } else {
                            e.target.setCustomValidity(
                              "Enter a valid phone number (10 digits, starting with 6, 7, 8, or 9)"
                            );
                          }
                        }}
                        onInvalid={(e) =>
                          e.target.setCustomValidity(
                            "Enter a valid phone number (10 digits, starting with 6, 7, 8, or 9)"
                          )
                        }
                        required
                      />
                    </div>
                    <div className="mb-3 w-50">
                      <input
                        type="tel"
                        className="form-control rounded-1"
                        placeholder="Pin Code"
                        value={pincode}
                        onChange={(e) => setPincode(e.target.value)}
                        required
                      />
                    </div>
                  </div>

                  <div className="d-flex justify-content-center mb-2">
                    <button
                      className="btn em-btn rounded-1 py-2 w-100"
                      style={{ fontSize: "0.8em" }}
                    >
                      Submit
                    </button>
                  </div>
                  {showMessage && (
                    <p
                      style={{ fontSize: "0.8em", color: "black" }}
                      className="text-center m-0 inter-medium"
                    >
                      {showMessage}
                    </p>
                  )}
                </form>
              </div>
            </div>
          </div>
        </div>
        <div className="">
          <div className="position-relative">
            <img
              src="https://ar-euro.s3.ap-south-1.amazonaws.com/india-webiste-17-02-24/landing+page/gudiPadwa/secondSection.png"
              className="w-100"
            ></img>
            <div className="secondSection-Text inter-light py-3 px-3 px-lg-0">
              <p className="">Think cycling, but better!</p>
              <ul>
                <li>
                  E-cycle = bike + motor + battery for an effortless ride.
                </li>
                <li>Climb hills, ride farther, commute smarter.</li>
                <li>Eco-friendly, fast, and fun!</li>
              </ul>
            </div>
          </div>
        </div>
        <div className="d-lg-none container p-0">
          <div className="">
            <div
              className="p-3 rounded-3"
              style={{ background: "white", border: "1px solid black" }}
            >
              <h5 className="mb-3 text-center text-dark">Fill the Details</h5>
              <form className="" onSubmit={pincodeSubmit}>
                <div className="mb-3">
                  <input
                    type="text"
                    className="form-control rounded-1"
                    placeholder="Name"
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                    required
                  />
                </div>
                <div className="d-flex" style={{ gap: "0.5em" }}>
                  <div className="mb-3 w-50">
                    <input
                      type="tel"
                      className="form-control rounded-1"
                      placeholder="Phone Number"
                      value={phoneNumber}
                      onChange={(e) => {
                        const phoneNumber = e.target.value.slice(0, 12);
                        setPhoneNumber(phoneNumber);
                        if (/^[6-9]\d{9}$/.test(phoneNumber)) {
                          e.target.setCustomValidity("");
                        } else {
                          e.target.setCustomValidity(
                            "Enter a valid phone number (10 digits, starting with 6, 7, 8, or 9)"
                          );
                        }
                      }}
                      onInvalid={(e) =>
                        e.target.setCustomValidity(
                          "Enter a valid phone number (10 digits, starting with 6, 7, 8, or 9)"
                        )
                      }
                      required
                    />
                  </div>
                  <div className="mb-3 w-50">
                    <input
                      type="tel"
                      className="form-control rounded-1"
                      placeholder="Pin Code"
                      value={pincode}
                      onChange={(e) => setPincode(e.target.value)}
                      required
                    />
                  </div>
                </div>

                <div className="d-flex justify-content-center mb-2">
                  <button
                    className="btn em-btn rounded-1 py-2 w-100"
                    style={{ fontSize: "0.8em" }}
                  >
                    Submit
                  </button>
                </div>
                {showMessage && (
                  <p
                    style={{ fontSize: "0.75em", color: "#00FF7F" }}
                    className="text-center m-0 metropolis-medium"
                  >
                    {showMessage}
                  </p>
                )}
              </form>
            </div>
          </div>
        </div>
        <div className="py-4 py-lg-5 my-lg-5 px-3 px-lg-0 position-relative">
          <div
            className="position-absolute d-none d-lg-block"
            style={{ left: "-8%", zIndex: "-1" }}
          >
            <img src="https://ar-euro.s3.ap-south-1.amazonaws.com/india-webiste-17-02-24/landing+page/gudiPadwa/Frame.png"></img>
          </div>
          <div
            className="position-absolute d-none d-lg-block"
            style={{ right: "-13%", top: "50%", zIndex: "-1" }}
          >
            <img src="https://ar-euro.s3.ap-south-1.amazonaws.com/india-webiste-17-02-24/landing+page/gudiPadwa/Frame.png"></img>
          </div>
          <div
            className="position-absolute d-none d-lg-block"
            style={{ right: "10%", top: "105%", zIndex: "-1" }}
          >
            <img
              src="https://ar-euro.s3.ap-south-1.amazonaws.com/india-webiste-17-02-24/landing+page/gudiPadwa/Frame.png"
              className="w-50"
            ></img>
          </div>
          <div className="text-md-center mb-3 mb-lg-4">
            <h3 className="sub-heading">Everyday Struggles</h3>
            <h2 className="heading inter-semi-bold">
              One Electric <span className="dhoni-animal-color">Solution</span>
            </h2>
          </div>
          <div className="container px-0 py-3">
            <div className="usp-card mb-4 pb-3 mb-lg-5 pb-lg-4">
              <div className="p-0 col-md-6 dhoni-animal-border rounded-3 p-1">
                <img
                  src="https://ar-euro.s3.ap-south-1.amazonaws.com/india-webiste-17-02-24/landing+page/gudiPadwa/USP+Cards/C.jpg"
                  className="img-fluid"
                ></img>
              </div>
              <div className="py-lg-4 px-lg-5 p-3 col-md-6 text-center text-md-start dhoni-animal-border rounded-3 ">
                <p className="usp-heading mb-2" style={{ lineHeight: "1.3em" }}>
                  Burn Calories,<br></br>
                  <span className="dhoni-animal-color">Commute Smarter</span>
                </p>
                <p className="usp-text mb-0">
                  Cover 10K steps in<br className="d-none d-lg-block"></br> just
                  20 minutes!
                </p>
              </div>
            </div>
            <div className="usp-card mb-4 pb-3 mb-lg-5 pb-lg-4">
              <div className="py-lg-4 px-lg-5 p-3 col-md-6 text-center text-md-end dhoni-animal-border rounded-3 d-none d-lg-block ">
                <p className="usp-heading mb-2" style={{ lineHeight: "1.3em" }}>
                  Charge Anytime, <br></br>
                  <span className="dhoni-animal-color">Anywhere</span>
                </p>
                <p className="usp-text mb-0">
                  Just like your
                  <br className="d-none d-lg-block"></br>smartphone!
                </p>
              </div>
              <div className="p-0 col-md-6 dhoni-animal-border rounded-3 p-1">
                <img
                  src="https://ar-euro.s3.ap-south-1.amazonaws.com/india-webiste-17-02-24/landing+page/gudiPadwa/USP+Cards/D_.jpg"
                  className="img-fluid"
                ></img>
              </div>
              <div className="py-lg-4 px-lg-5 p-3 col-md-6 text-center text-md-start dhoni-animal-border rounded-3 d-lg-none ">
                <p className="usp-heading mb-2" style={{ lineHeight: "1.3em" }}>
                  Charge Anytime, <br></br>
                  <span className="dhoni-animal-color">Anywhere</span>
                </p>
                <p className="usp-text mb-0">
                  Just like your
                  <br className="d-none d-lg-block"></br> smartphone!
                </p>
              </div>
            </div>
            <div className="usp-card mb-4 pb-3 mb-lg-5 pb-lg-4">
              <div className="p-0 col-md-6 dhoni-animal-border rounded-3 p-1">
                <img
                  src="https://ar-euro.s3.ap-south-1.amazonaws.com/india-webiste-17-02-24/landing+page/gudiPadwa/USP+Cards/A.jpg"
                  className="img-fluid"
                ></img>
              </div>
              <div className="py-lg-4 px-lg-5 p-3 col-md-6 text-center text-md-start dhoni-animal-border rounded-3 ">
                <p className="usp-heading mb-2" style={{ lineHeight: "1.3em" }}>
                  No License,<br></br>
                  <span className="dhoni-animal-color">No Restrictions</span>
                </p>
                <p className="usp-text mb-0">
                  Enjoy a max.<br className="d-none d-lg-block"></br> speed of
                  25 KM/Hr
                </p>
              </div>
            </div>
            <div className="usp-card mb-4 pb-3 mb-lg-5 pb-lg-4">
              <div className="py-lg-4 px-lg-5 p-3 col-md-6 text-center text-md-end dhoni-animal-border rounded-3 d-none d-lg-block ">
                <p className="usp-heading mb-2" style={{ lineHeight: "1.3em" }}>
                  No Fuel,<br></br>
                  <span className="dhoni-animal-color">No Expenses</span>
                </p>
                <p className="usp-text mb-0">
                  Go green and save <br className="d-none d-lg-block"></br>
                  big with an e-cycle
                </p>
              </div>
              <div className="p-0 col-md-6 dhoni-animal-border rounded-3 p-1">
                <img
                  src="https://ar-euro.s3.ap-south-1.amazonaws.com/india-webiste-17-02-24/landing+page/gudiPadwa/USP+Cards/B.jpg"
                  className="img-fluid"
                ></img>
              </div>
              <div className="py-lg-4 px-lg-5 p-3 col-md-6 text-center text-md-start dhoni-animal-border rounded-3 d-lg-none ">
                <p className="usp-heading mb-2" style={{ lineHeight: "1.3em" }}>
                  No Fuel,<br></br>
                  <span className="dhoni-animal-color">No Expenses</span>
                </p>
                <p className="usp-text mb-0">
                  Go green and save <br className="d-none d-lg-block"></br>
                  big with an e-cycle
                </p>
              </div>
            </div>
            <div className="usp-card mb-4">
              <div className="p-0 col-md-6 dhoni-animal-border rounded-3 p-1">
                <img
                  src="https://ar-euro.s3.ap-south-1.amazonaws.com/india-webiste-17-02-24/landing+page/gudiPadwa/USP+Cards/E.jpg"
                  className="img-fluid"
                ></img>
              </div>
              <div className="py-lg-4 px-lg-5 p-3 col-md-6 text-center text-md-start dhoni-animal-border rounded-3 ">
                <p className="usp-heading mb-2" style={{ lineHeight: "1.3em" }}>
                  The Smartest Ride<br></br>
                  <span className="dhoni-animal-color">for Your Kid</span>
                </p>
                <p className="usp-text mb-0">
                  Keeps them safe, active,{" "}
                  <br className="d-none d-lg-block"></br> and away from screens
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="container p-0 pb-5 mb-lg-5">
          <div>
            <h2 className="heading inter-semi-bold">Power.</h2>
            <h2 className="heading inter-semi-bold">Performance.</h2>
            <h2 className="heading inter-semi-bold">
              <span className="dhoni-animal-color">Trust.</span>
            </h2>
          </div>
          <div className="d-flex d-lg-none mt-4" style={{ gap: "1em" }}>
            <div className="w-50 mb-4">
              <div
                className="dhoni-animal-border p-3 d-flex justify-content-center align-items-center rounded-3 dhoni-animal-bg-primary"
                style={{
                  flexDirection: "column",
                  WebkitMaskImage:
                    "linear-gradient(to bottom, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 1) 40%)",
                  maskImage:
                    "linear-gradient(to bottom, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 1) 40%)",
                }}
              >
                <div className="mb-2 d-flex justify-content-center">
                  <img
                    src="https://ar-euro.s3.ap-south-1.amazonaws.com/india-webiste-17-02-24/landing+page/gudiPadwa/icons/warranty.png"
                    className="w-50 mt-3"
                  ></img>
                </div>
                <div className="text-center">
                  <small className="mb-0 text-center">
                    Up to Lifetime Warranty*
                  </small>
                </div>
              </div>
            </div>
            <div className="w-50 mb-4">
              <div
                className="dhoni-animal-border p-3 d-flex justify-content-center align-items-center rounded-3 dhoni-animal-bg-primary"
                style={{
                  flexDirection: "column",
                  WebkitMaskImage:
                    "linear-gradient(to bottom, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 1) 40%)",
                  maskImage:
                    "linear-gradient(to bottom, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 1) 40%)",
                }}
              >
                <div className="mb-2 pb-1 d-flex justify-content-center">
                  <img
                    src="https://ar-euro.s3.ap-south-1.amazonaws.com/india-webiste-17-02-24/landing+page/gudiPadwa/icons/dealership.png"
                    className="w-50 mt-3"
                  ></img>
                </div>
                <div className="text-center">
                  <small className="mb-0 text-center">
                    550+ Dealerships Across India
                  </small>
                </div>
              </div>
            </div>
          </div>
          <div className="d-flex d-lg-none" style={{ gap: "1em" }}>
            <div className="w-50 mb-4">
              <div
                className="dhoni-animal-border p-3 d-flex justify-content-center align-items-center rounded-3 dhoni-animal-bg-primary"
                style={{
                  flexDirection: "column",
                  WebkitMaskImage:
                    "linear-gradient(to bottom, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 1) 40%)",
                  maskImage:
                    "linear-gradient(to bottom, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 1) 40%)",
                }}
              >
                <div className="mb-2 d-flex justify-content-center">
                  <img
                    src="https://ar-euro.s3.ap-south-1.amazonaws.com/india-webiste-17-02-24/landing+page/gudiPadwa/icons/service.png"
                    className="w-50 mt-3"
                  />
                </div>
                <div className="text-center">
                  <small className="mb-0 text-center">
                    24/7 Pan India Service
                  </small>
                </div>
              </div>
            </div>
            <div className="w-50 mb-4">
              <div
                className="dhoni-animal-border p-3 d-flex justify-content-center align-items-center rounded-3 dhoni-animal-bg-primary"
                style={{
                  flexDirection: "column",
                  WebkitMaskImage:
                    "linear-gradient(to bottom, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 1) 40%)",
                  maskImage:
                    "linear-gradient(to bottom, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 1) 40%)",
                }}
              >
                <div className="mb-2 d-flex justify-content-center">
                  <img
                    src="https://ar-euro.s3.ap-south-1.amazonaws.com/india-webiste-17-02-24/landing+page/gudiPadwa/icons/india.png"
                    className="w-50 mt-3"
                  ></img>
                </div>
                <div className="text-center">
                  <small className="mb-0 text-center">
                    Proudly Made in India
                  </small>
                </div>
              </div>
            </div>
          </div>
          <div className="d-lg-block d-none mt-5">
            <Swiper
              spaceBetween={20}
              slidesPerView={1.05}
              initialSlide={0}
              centeredSlides={true}
              loop={true}
              modules={[Pagination, Navigation]}
              pagination={{ clickable: true }}
              simulateTouch={true}
              slide
              speed={1000}
              breakpoints={{
                1024: {
                  slidesPerView: 4,
                  spaceBetween: 40,
                  centeredSlides: false,
                  loop: false,
                  pagination: false,
                  simulateTouch: false,
                },
              }}
            >
              <SwiperSlide>
                <div
                  className="dhoni-animal-border p-3 d-flex justify-content-center align-items-center rounded-3 dhoni-animal-bg-primary"
                  style={{
                    flexDirection: "column",
                    WebkitMaskImage:
                      "linear-gradient(to bottom, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 1) 40%)",
                    maskImage:
                      "linear-gradient(to bottom, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 1) 40%)",
                  }}
                >
                  <div className="mb-3 pb-2 d-flex justify-content-center">
                    <img
                      src="https://ar-euro.s3.ap-south-1.amazonaws.com/india-webiste-17-02-24/landing+page/gudiPadwa/icons/warranty.png"
                      className="w-75 mt-4"
                    ></img>
                  </div>
                  <div className="text-center">
                    <p className="mb-0 text-center">
                      Up to Lifetime <br></br>Warranty*
                    </p>
                  </div>
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div
                  className="dhoni-animal-border p-3 d-flex justify-content-center align-items-center rounded-3 dhoni-animal-bg-primary"
                  style={{
                    flexDirection: "column",
                    WebkitMaskImage:
                      "linear-gradient(to bottom, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 1) 40%)",
                    maskImage:
                      "linear-gradient(to bottom, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 1) 40%)",
                  }}
                >
                  <div className="mb-3 pb-3 d-flex justify-content-center">
                    <img
                      src="https://ar-euro.s3.ap-south-1.amazonaws.com/india-webiste-17-02-24/landing+page/gudiPadwa/icons/dealership.png"
                      className="w-75 mt-4"
                    ></img>
                  </div>
                  <div className="text-center">
                    <p className="mb-0 text-center">
                      550+ Dealerships Across <br></br>India
                    </p>
                  </div>
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div
                  className="dhoni-animal-border p-3 d-flex justify-content-center align-items-center rounded-3 dhoni-animal-bg-primary"
                  style={{
                    flexDirection: "column",
                    WebkitMaskImage:
                      "linear-gradient(to bottom, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 1) 40%)",
                    maskImage:
                      "linear-gradient(to bottom, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 1) 40%)",
                  }}
                >
                  <div className="mb-3 pb-3 d-flex justify-content-center">
                    <img
                      src="https://ar-euro.s3.ap-south-1.amazonaws.com/india-webiste-17-02-24/landing+page/gudiPadwa/icons/service.png"
                      className="w-75 mt-4"
                    ></img>
                  </div>
                  <div className="text-center">
                    <p className="mb-0 text-center">
                      24/7 Pan India <br></br>Service
                    </p>
                  </div>
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div
                  className="dhoni-animal-border p-3 d-flex justify-content-center align-items-center rounded-3 dhoni-animal-bg-primary"
                  style={{
                    flexDirection: "column",
                    WebkitMaskImage:
                      "linear-gradient(to bottom, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 1) 40%)",
                    maskImage:
                      "linear-gradient(to bottom, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 1) 40%)",
                  }}
                >
                  <div className="mb-3 pb-1 d-flex justify-content-center">
                    <img
                      src="https://ar-euro.s3.ap-south-1.amazonaws.com/india-webiste-17-02-24/landing+page/gudiPadwa/icons/india.png"
                      className="w-75 mt-4"
                    ></img>
                  </div>
                  <div className="text-center">
                    <p className="mb-0 text-center">
                      Proudly Made in <br></br>India
                    </p>
                  </div>
                </div>
              </SwiperSlide>
            </Swiper>
          </div>
        </div>
        <div className="container p-0 pb-5 mb-lg-5">
          <div className="mb-4 mb-lg-5">
            <h2 className="heading inter-semi-bold text-md-center">
              A Mode For <span className="dhoni-animal-color">Every Mood</span>
            </h2>
          </div>
          <Swiper
            spaceBetween={20}
            slidesPerView={1.3}
            initialSlide={0}
            centeredSlides={true}
            modules={[Pagination, Navigation]}
            pagination={false}
            loop={true}
            simulateTouch={true}
            slide
            speed={1000}
            breakpoints={{
              1024: {
                slidesPerView: 3,
                spaceBetween: 30,
                centeredSlides: false,
                loop: false,
                pagination: false,
                simulateTouch: false,
              },
            }}
          >
            <SwiperSlide>
              <div className="mb-3">
                <div className="dhoni-animal-border rounded-3">
                  <video
                    autoPlay
                    muted
                    loop
                    disableRemotePlayback
                    playsInline
                    className="img-fluid p-2 pb-0"
                  >
                    <source src="https://ar-euro.s3.ap-south-1.amazonaws.com/india-webiste-17-02-24/landing+page/gudiPadwa/Gifs/PAS.mp4" />
                    Your browser does not support the video tag.
                  </video>
                </div>
                <p className="mb-0 inter-medium mode-card-text text-center">
                  Pedal Assist Mode
                </p>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="mb-3">
                <div className="dhoni-animal-border rounded-3">
                  <video
                    autoPlay
                    muted
                    loop
                    disableRemotePlayback
                    playsInline
                    className="img-fluid p-2 pb-0"
                  >
                    <source src="https://ar-euro.s3.ap-south-1.amazonaws.com/india-webiste-17-02-24/landing+page/gudiPadwa/Gifs/Mechanical.mp4" />
                    Your browser does not support the video tag.
                  </video>
                </div>
                <p className="mb-0 inter-medium mode-card-text text-center">
                  Mechanical Mode
                </p>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="mb-3">
                <div className="dhoni-animal-border rounded-3">
                  <video
                    autoPlay
                    muted
                    loop
                    disableRemotePlayback
                    playsInline
                    className="img-fluid p-2 pb-0"
                  >
                    <source src="https://ar-euro.s3.ap-south-1.amazonaws.com/india-webiste-17-02-24/landing+page/gudiPadwa/Gifs/Throttle.mp4" />
                    Your browser does not support the video tag.
                  </video>
                </div>
                <p className="mb-0 inter-medium mode-card-text text-center">
                  Throttle Mode
                </p>
              </div>
            </SwiperSlide>
          </Swiper>
        </div>
        <div className="p-0 pb-5 mb-lg-5 bike-features">
          <div className="mb-4 mb-lg-5 container p-0">
            <h2 className="heading inter-semi-bold text-md-center">
              Ride Smart.
              <span className="dhoni-animal-color">Ride Electric.</span>
            </h2>
          </div>
          <Swiper
            spaceBetween={20}
            slidesPerView={1.3}
            initialSlide={0}
            centeredSlides={true}
            loop={true}
            simulateTouch={true}
            slide
            speed={1000}
            modules={[Pagination, Navigation]}
            pagination={false}
            breakpoints={{
              1024: {
                slidesPerView: 3.5,
                spaceBetween: 30,
                centeredSlides: true,
                loop: true,
                pagination: true,
                simulateTouch: true,
              },
            }}
          >
            {bikeFeatures.map((feature, index) => (
              <SwiperSlide>
                <div className="">
                  <div className="mb-2 dhoni-animal-border rounded-3 dhoni-animal-bg-primary">
                    <picture className="main-banner-img">
                      <source
                        media="(max-width:650px)"
                        srcSet={feature.deskImage}
                      />
                      <source
                        media="(min-width:651px)"
                        srcSet={feature.deskImage}
                      />
                      <img
                        src={feature.deskImage}
                        alt="Banner1"
                        className="d-block w-100 rounded-3"
                      />
                    </picture>
                  </div>
                  <div className=" px-2">
                    <h4 className="inter-medium mb-1">{feature.heading}</h4>
                    <p className="inter-light">{feature.subText}</p>
                  </div>
                </div>
              </SwiperSlide>
            ))}
          </Swiper>
        </div>
        <div className="container p-0 pb-5 mb-lg-5">
          <div>
            <img
              src="https://ar-euro.s3.ap-south-1.amazonaws.com/india-webiste-17-02-24/landing+page/gudiPadwa/Breaker.jpg"
              className="w-100"
            ></img>
          </div>
        </div>
        <div className="p-0 pb-5 mb-lg-5">
          <div className="mb-4 mb-lg-5 container p-0">
            <h2 className="heading inter-semi-bold text-md-center">
              Celebs Loving{" "}
              <span className="dhoni-animal-color">Our Ebikes</span>
            </h2>
          </div>
          <Swiper
            spaceBetween={20}
            slidesPerView={1.3}
            initialSlide={0}
            centeredSlides={true}
            loop={true}
            simulateTouch={true}
            slide
            speed={1000}
            modules={[Pagination, Navigation]}
            pagination={false}
            breakpoints={{
              1024: {
                slidesPerView: 4.5,
                spaceBetween: 30,
                centeredSlides: true,
                loop: true,
                pagination: true,
                simulateTouch: true,
              },
              1600: {
                slidesPerView: 6,
                spaceBetween: 30,
                centeredSlides: true,
                loop: true,
                pagination: true,
                simulateTouch: true,
              },
            }}
          >
            {celebs.map((celeb, index) => (
              <SwiperSlide>
                <div className="dhoni-animal-border p-3 rounded-3">
                  <div className="mb-3">
                    <img
                      className="img-fluid rounded-3"
                      src={celeb.image}
                    ></img>
                  </div>
                  <div className="text-center">
                    <p className="inter-semi-bold mb-1">{celeb.name}</p>
                    <small>{celeb.designation}</small>
                  </div>
                </div>
              </SwiperSlide>
            ))}
          </Swiper>
        </div>
      </div>
    </>
  );
};

export default DhoniCampaignScreen;
